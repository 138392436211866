import { Button, Caption2, LargeTitle, Link, makeStyles, mergeClasses, Text } from '@fluentui/react-components';
import { Airplane24Regular, BriefcaseMedical24Regular, Call24Regular, ClipboardTextEdit24Regular, Payment24Regular } from '@fluentui/react-icons';
import classNames from 'classnames';
import React, { useState } from 'react';
import './App.css';

const useStyles = makeStyles({
  actionButton: {
    backgroundColor: '#FFC200',
    maxWidth: '180px',
    height: '60px',
    color: 'black',
    fontSize: '18px',
    marginTop: '36px',
  },
  divider: {
    paddingTop: "15px",
    paddingBottom: "15px"
  },
  navbarHeaderText: {
    paddingLeft: '6px',
    marginBottom: '6px',
  },
  navbarLink: {
    width: '100%',
    paddingLeft: '12px',
    paddingTop: '6px',
    paddingBottom: '6px',
  },
  stack: {
    // must be merged with stackHorizontal or stackVertical
    display: "flex",
    flexWrap: "nowrap",
    width: "fit-content",
    height: "fit-content",
    boxSizing: "border-box",
    "> *": {
      textOverflow: "ellipsis"
    }
  },
  stackHorizontal: {
    // overrides for horizontal stack
    flexDirection: "row",
    "> :not(:last-child)": {
      marginRight: "12px"
    }
  },
  stackitem: {
    height: "fit-content",
    width: "fit-content",
    alignSelf: "center",
    flexShrink: 1
  },
  stackVertical: {
    // overrides for vertical stack
    flexDirection: "column",
    "> :not(:first-child)": {
      marginTop: "10px"
    }
  }
});

export const App: React.FunctionComponent = () => {

  const [isNavVisible, setIsNavVisible] = useState(false);
  const classes = useStyles();

  const stackHorizontalClass = mergeClasses(
    classes.stack,
    classes.stackHorizontal
  );

  const stackVerticalClass = mergeClasses(
    classes.stack,
    classes.stackVertical
  );

  const contactDataClass = mergeClasses(
    stackHorizontalClass,
    'start-contact-data',
  );

  const headerRightCallCtaClass = mergeClasses(
    classes.stackitem,
    'header-right-call-cta'
  );

  const orderTaxiButtonClass = mergeClasses(
    classes.actionButton,
    'order-taxi-button',
  );

  const displayNav = () => {
    setIsNavVisible(!isNavVisible);
  };

  const scrollToStart = () => {
    scrollToElementAndHideNav('#start');
  };

  const scrollToContact = () => {
    scrollToElementAndHideNav('#contact-data');
  };

  const scrollToService = () => {
    scrollToElementAndHideNav('#service');
  };

  const scrollToElementAndHideNav = (elementId: string) => {

    const anchor = document.getElementById(elementId);

    if (!!anchor) {
      scrollToElement(anchor);
      setIsNavVisible(false);
    }
  };

  const scrollToElement = (element: HTMLElement) => {
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const navBarMenuClassName = classNames('navbar-menu', {
    'navbar-menu-navbar-visible': isNavVisible,
  });

  const navBarClassName = classNames('navbar', {
    'navbar-visible': isNavVisible,
    'navbar-hidden': !isNavVisible,
  });

  return (
    <>
      <span id="#start" />

      {/* Nav-Bar */}
      <div className="header-fixed">
        <div
          className={stackHorizontalClass}
          style={{
            height: '100%',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <div className={classes.stackitem}>
            <div className={stackHorizontalClass}>
              <div className={classes.stackitem}>
                <div className={navBarMenuClassName} onClick={displayNav}>
                  <span className="navbar-menu-line navbar-menu-line-1" />
                  <span className="navbar-menu-line navbar-menu-line-2" />
                  <span className="navbar-menu-line navbar-menu-line-3" />
                </div>
              </div>

              <Text size={500} weight={'semibold'} style={{ marginTop: '3px' }}>
                Taxi<span style={{ fontWeight: 700, fontSize: 20, color: '#FFC200' }}>4</span>Mainz
              </Text>
            </div>
          </div>

          <div className={headerRightCallCtaClass} style={{ marginRight: '18px' }}>
            <div className={stackHorizontalClass}>
              <Link href="tel:+49-6131-608-7590" style={{ paddingTop: '6px' }}>
                <Call24Regular style={{ fontSize: 28, color: '#FFC200' }} />
              </Link>
              <Link appearance='subtle' href="tel:+49-6131-608-7590" style={{ marginTop: '6px' }}>
                <Text weight='semibold'>
                  06131 - 608 7590
                </Text>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="header-fixed-placeholder" />

      <div className={navBarClassName}>
        <div
          style={{
            marginTop: '18px',
            marginLeft: '0px',
          }}
        >
          <div className={classes.navbarHeaderText}>
            <Text weight='semibold' size={500}>Navigation</Text>
          </div>
          <div className={classes.stackVertical}>
            <Link appearance='subtle' className={classes.navbarLink} onClick={scrollToStart}>
              Start
            </Link>
            <Link appearance='subtle' className={classes.navbarLink} onClick={scrollToContact}>
              Kontakt
            </Link>
            <Link appearance='subtle' className={classes.navbarLink} onClick={scrollToService}>
              Service
            </Link>
          </div>
        </div>
      </div>

      <div className="content">
        <div className={stackVerticalClass} style={{ alignItems: 'center', width: '100%', marginTop: '24px' }}>
          <div className={stackVerticalClass} style={{ alignItems: 'center' }}>
            {/* Title and hero image */}
            <LargeTitle>
              Taxi<span style={{ fontWeight: 700, fontSize: 42, color: '#FFC200' }}>4</span>Mainz
            </LargeTitle>

            <Text size={500} style={{ textAlign: 'center' }}>
              IHR ZUVERLÄSSIGES UND SCHNELLES TAXIUNTERNEHMEN
            </Text>

            <div className={stackHorizontalClass}>
              <Button
                as='a'
                appearance="primary"
                className={classes.actionButton}
                href="tel:+49-6131-608-7590"
              >
                Jetzt anrufen
              </Button>

              <Button
                appearance="primary"
                className={orderTaxiButtonClass}
                onClick={scrollToContact}
              >
                Taxi Bestellen
              </Button>
            </div>

            <img
              src="./assets/hero.jpg"
              alt="Taxi4Mainz"
              className="start-image"
            />

            {/* Contact-Data */}
            <div
              id="#contact-data"
              className={contactDataClass}
            >
              <div className={classes.stackitem}>
                <Text size={500}>
                  Tel: <a href="tel:+49-6131--608-7590">06131 - 608 7590</a>
                </Text>
              </div>

              <div className={classes.stackitem}>
                <Text size={500}>
                  Mobil: <a href="tel:+49-176-55-333-180">0176 - 55 333 180</a>
                </Text>
              </div>

              <div className={classes.stackitem}>
                <Text size={500}>
                  EMail: <a href="mailto:team@taxi4mainz.com">team@taxi4mainz.com</a>
                </Text>
              </div>
            </div>

            {/* Contact-Form */}
            <div
              id="order-taxi-form-stack"
              style={{
                width: '100%',
                marginTop: '36px'
              }}
            >
              <iframe
                title="order-taxi-form"
                src="https://taxi4mainz.com/pashmak/Taxi-Bestellung.html"
                style={{
                  width: '100%',
                  height: '360px',
                  border: 'none',
                }}
              />
            </div>

            {/* Services */}
            <div
              id="#service"
              className={stackVerticalClass}
              style={{ alignItems: 'left', width: '100%' }}
            >
              <div className={stackHorizontalClass}>
                <Airplane24Regular />
                <Text>FLUGHAFENTRANSFER</Text>
              </div>

              <div className={stackHorizontalClass}>
                <BriefcaseMedical24Regular />
                <Text>KRANKENFAHRTEN</Text>
              </div>

              <div className={stackHorizontalClass}>
                <ClipboardTextEdit24Regular />
                <Text>RECHNUNGSFAHRTEN</Text>
              </div>

              <div className={stackHorizontalClass}>
                <Payment24Regular />
                <Text>KREDITKARTENZAHLUNG</Text>
              </div>
            </div>

            {/* Impressum */}
            <div className={stackHorizontalClass}>
              <Caption2>Impressum: Taxi4Mainz (Inh. Habashi), An der Fahrt 4, 55124 Mainz</Caption2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
