import { FluentProvider, teamsLightTheme } from '@fluentui/react-components';
import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';

ReactDOM.render(
  <React.Fragment>
    <FluentProvider theme={teamsLightTheme}>
      <App />
    </FluentProvider>
  </React.Fragment>,
  document.getElementById('root')
);
